import React from "react"
import { graphql, Link } from "gatsby"
import gravatar from 'gravatar'
import moment from 'moment'
import Layout from "../components/layout"
import Mailchimp from "../components/mailchimp"
import defaultCover from "../images/covers/blog-default.png"

const Blog = ({ data }) => {

    const { site, allMarkdownRemark } = data

    return (
        <Layout 
            title="Blog"
            description=""
        >
            <div className="container mt-12">
                <div className="flex justify-between items-center mb-12">
                    <h1 className="mb-0">Blog</h1>
                    <div>
                        <a href={`${site.siteMetadata.siteUrl}/blog/rss.xml`} className="bg-gray-600 text-gray-100 rounded px-3 py-2 hover:bg-gray-500"><i className="fas fa-rss fa-lg" /></a>
                    </div>
                </div>

                { !allMarkdownRemark.edges.length && <p className="italic">No posts here yet...</p>}

                { allMarkdownRemark.edges.map(post => (
                    <Link to={post.node.frontmatter.slug} key={post.node.frontmatter.slug} className="block rounded shadow bg-white mb-12 flex overflow-hidden hover:opacity-75">
                        {/* Open graph cover images using ratio of 1.9:1 (https://developers.facebook.com/docs/sharing/webmasters/images/) */}
                        <div className="bg-white flex items-center px-3" style={{width: '300px'}}>
                            <img src={post.node.frontmatter.coverImage ? post.node.frontmatter.coverImage.publicURL : defaultCover} alt="Cover" className="w-full" />
                        </div>

                        <div className="flex-grow px-8 py-5">
                            <h3 className="mb-0">
                                {post.node.frontmatter.title}
                            </h3>
                            <p className="text-sm text-gray-500 italic mb-4 flex items-center">
                                <img 
                                    src={gravatar.url(post.node.frontmatter.authorEmail || 'unknown-author@tail.dev', {d: 'mp'})} 
                                    alt="Author avatar" 
                                    className="inline-block w-4 rounded-full mr-1"
                                />

                                <span>
                                    {post.node.frontmatter.author || ''} on {post.node.frontmatter.date ? moment(post.node.frontmatter.date).format('MMMM Do YYYY') : '-'}
                                </span>
                            </p>

                            <span className="text-gray-500">
                                {post.node.excerpt}
                            </span>
                        </div>
                    </Link>
                )) }
            </div>

            <div className="text-center">
              <h4 className="mb-1">Get monthly updates</h4>
              <p className="text-gray-400">No bloat, just new platform features once a month.</p>
              <div style={{width: '350px'}} className="mx-auto">
                <Mailchimp />
              </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
{
    site {
        siteMetadata {
            siteUrl
        }
    },
    allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date]},
        filter: {
            fileAbsolutePath: {regex: "/\/posts/"},
            frontmatter: {published: { eq: true }}
        }
    ) {
        edges {
            node {
                excerpt
                frontmatter {
                    title
                    slug
                    date
                    author
                    authorEmail
                    coverImage {
                        publicURL
                    }
                }
            }
        }
    }
}
`

export default Blog
